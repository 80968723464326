import api from '@/services/ApiService'

class ProjectsUserService{
  async projectsList() {
    const response = await api.get('/projectsuser')
    return response.data
  }
  async projectsVersion(id) {
    const response = await api.get('/projectsuser/version/' + id)
    return response.data
  }
}

export default new ProjectsUserService()
