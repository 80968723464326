<template>
  <v-app  :class="{'theme-light' : !useThemeStore().black, 'theme-dark' : useThemeStore().black}">
    <v-navigation-drawer>
      <div>
        <div class="logo-nav mb-4 mt-2" style="margin-top:0px;">
          <img src="../assets/logo.svg" style="width:50px;">
          <div class="text-primary pl-1">
            <div style="font-size:1.7em;font-weight:600!important;color:#1e2931;">QuantOps</div>
          </div>
        </div>

        <div class="nav-cat">Général</div>

        <router-link :to="'/dashboard'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Dashboard'}">
            <div class="nav-item-text">

              <v-icon>mdi-view-dashboard-outline</v-icon>
              <div class="text">Dashboard</div>
            </div>
          </div>
        </router-link>

        <router-link :to="'/homeoffice'" v-if="Authservice.hasAccess('dev_homeoffice')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'homeOfficePage'}">
            <div class="nav-item-text">
              <v-icon>mdi-home-account</v-icon>
              <div class="text">Télétravail</div>
            </div>
          </div>
        </router-link>

        <router-link :to="'/monitoring'" v-if="Authservice.hasAccess('dev_monitoring')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'monitoringPage'}">
            <div class="nav-item-text">
              <v-icon>mdi-chart-bar</v-icon>
              <div class="text">Monitoring</div>
            </div>
          </div>
        </router-link>

        <div class="nav-cat" v-if="Authservice.hasAccess('dev_cluster') || Authservice.hasAccess('cluster_manage')">Devops</div>

        <router-link :to="'/clusters'" v-if="Authservice.hasAccess('dev_cluster') || Authservice.hasAccess('cluster_manage')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'ClusterDev' || $route.name === 'Namespace'}">
            <div class="nav-item-text">
              <v-icon>mdi-kubernetes</v-icon>
              <div class="text">Clusters</div>
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('projects_manage') || Authservice.hasAccess('projects_view')" :to="'/projects'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Projects' || $route.name === 'ProjectsAdd' || $route.name === 'ProjectsEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-source-repository</v-icon>
              <div class="text">Projets</div>
            </div>
          </div>
        </router-link>


        <div class="nav-cat" v-if="Authservice.hasAccess('users_manage') || Authservice.hasAccess('users_manage') || Authservice.hasAccess('group_manage') || Authservice.hasAccess('projects_manage') || Authservice.hasAccess('gitlab_manage') || Authservice.hasAccess('homeoffice_manage')">Admin</div>

        <router-link v-if="Authservice.hasAccess('users_manage')" :to="'/users'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Users' || $route.name === 'UsersAdd' || $route.name === 'UsersEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-account-outline</v-icon>
              <div class="text">Utilisateurs</div>
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('group_manage')" :to="'/groups'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Groups' || $route.name === 'GroupsAdd' || $route.name === 'GroupsEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-account-group</v-icon>
              <div class="text">Groupes</div>
            </div>
          </div>
        </router-link>



        <router-link v-if="Authservice.hasAccess('gitlab_manage')" :to="'/repositories'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'RepositoriesManagePage'}">
            <div class="nav-item-text">
              <v-icon>mdi-gitlab</v-icon>
              <div class="text">Gitlab</div>
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('homeoffice_manage')" :to="'/homeoffice/admin'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'HomeOfficeAdminListPage'}">
            <div class="nav-item-text">
              <v-icon>mdi-home</v-icon>
              <div class="text">Télétravail</div>
            </div>
          </div>
        </router-link>



        <div class="d-flex justify-start align-content-center right-top-bar login-bg" :class="{'login-bg-active' : accountMenu}">
          <img src="../assets/avatar.png" style="width:45px;height:45px;border-radius:50%;" class="avatar">
          <div class="pl-3 pt-0 flex-grow-1">
            <div>{{ userFullName }}</div>
            <div style="color:#8d6868;font-size:0.8em;">{{ user.type }}</div>
          </div>
          <div class="justify-center d-flex align-content-center pt-3">
            <v-icon>mdi-chevron-down</v-icon>
          </div>

          <v-menu activator="parent" v-model="accountMenu">
            <v-list>
              <v-list-item>
                <v-list-item-title @click="myAccount()">
                  <div class="nav-item-text">
                  <v-icon class="mr-3">mdi-account</v-icon>
                  Mon compte
                </div>
                </v-list-item-title>
                <v-divider class="mb-2 mt-2"></v-divider>
                <v-list-item-title @click="logout">
                  <div class="nav-item-text text-danger">
                  <v-icon class="mr-3 text-danger">mdi-power</v-icon>
                  Déconnexion
                </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

      </div>
    </v-navigation-drawer>

    <v-main class="pb-4">
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
//get the user from the store and the drawer state, and the mutation to change the drawer state and the computed property to get the user
import { useAuthStore } from '@/stores/AuthStore.js'
import router from '@/router/index.js'
import moment from 'moment';
import { useThemeStore } from '@/stores/ThemeStore.js';
import AuthService from '@/services/AuthService.js';

export default {
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  data() {
    return {
      showCluster: false,
      showError: false,
      clusters: [],
      moment: moment,
      Authservice: AuthService,
      accountMenu : false,
    }
  },
  mounted() {
    this.loadCluster();
  },
  computed: {
    user() {
      return this.authStore.user;
    },
    userFullName() {
      return `${ this.user?.firstname } ${ this.user?.lastname }`;
    }
  },
  methods: {
    useThemeStore,
    async loadCluster() {
      // DashboardService.clusterBar().then(response => {
      //   this.clusters = response;
      //   setTimeout(() => {this.loadCluster()}, 5000);
      // });
    },
    async logout() {
      this.authStore.logout();
      router.push({ name: 'Login' });
    },
    theme() {
      useThemeStore().switch();
    }
  }
}
</script>

<style>
</style>
