<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center; align-items: center">
        <Loader />
      </v-col>
    </v-row>

    <PageHeader subtitle="Modifier un utilisateur" v-if="!loading">
      <v-btn class="bg-primary btn" @click="createUser" :disabled="!valid">Sauvegarder</v-btn>
    </PageHeader>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Informations</v-card-title>
          <v-card-text class="pt-3">
            <v-form v-model="valid">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    label="E-mail"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.firstname"
                    :counter="10"
                    :rules="nameRules"
                    label="Prénom "
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.lastname"
                    :counter="10"
                    :rules="nameRules"
                    label="Nom"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Htaccess</v-card-title>
          <v-card-subtitle style="margin-top: -10px"
            >Utilisé pour accéder aux environnement kube</v-card-subtitle
          >
          <v-card-text class="pt-3">
            <v-form v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.htaccess_user"
                    :rules="typeRules"
                    label="Nom de compte"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.htaccess_password"
                    :rules="typeRules"
                    type="text"
                    label="Mot de passe"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Groupes</v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="4" v-for="group in this.groups">
                <input type="checkbox" v-model="form['groups'][group['id']]" />
                {{ group['name'] }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Projets</v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  label="Select"
                  v-model="form.projects"
                  :rules="typeRules"
                  :items="this.namespaces"
                  item-title="name"
                  item-value="id"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title> Mot de passe </v-card-title>
          <v-card-subtitle>Laisser vide si aucune modification</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-form v-model="valid">
                  <v-text-field
                    v-model="form.password"
                    label="Password"
                    type="password"
                    hide-details
                    required
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import { fr } from 'date-fns/locale'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import UsersService from '@/services/UsersService.js'
import { useAuthStore } from '@/stores/AuthStore.js'
import GroupsService from '@/services/GroupsService.js'
import PageHeader from '@/components/ui/bloc/PageHeader.vue'
import ProjectsService from '@/services/ProjectsService.js'
import Loader from '@/components/ui/Loader.vue'

moment.locale('fr')

export default {
  name: 'UsersEdit',
  computed: {
    fr() {
      return fr
    }
  },
  components: { Loader, PageHeader, GuestCard, BackButton },
  setup() {
    const authStore = useAuthStore()
    return { authStore }
  },
  mounted() {
    this.loading = true;
    if (!AuthService.hasAccess('users_manage')) {
      this.$router.push({ name: 'Dashboard' })
    }

    GroupsService.groupList().then((response) => {
      this.groups = response
    })

    ProjectsService.projectsListForSelect().then((response) => {
      this.namespaces = response['projects']
      UsersService.usersDetail(this.$route.params.id).then((response) => {
        this.form.firstname = response['user']['firstname']
        this.form.lastname = response['user']['lastname']
        this.form.email = response['user']['email']
        this.form.type = response['user']['type']
        this.form.projects = response['user']['projects']
        this.form.groups = response['user']['groups']
        this.form.htaccess_user = response['user']['htaccess_user']
        this.form.htaccess_password = response['user']['htaccess_password']
        this.loading = false;
      })
    })
  },
  data() {
    return {
      loading: false,
      valid: false,
      namespaces: [],
      groups: [],
      form: {
        firstname: null,
        lastname: null,
        email: null,
        type: null,
        password: null,
        htaccess_user: null,
        htaccess_password: null,
        projects: [],
        groups: []
      },
      passwordRules: [
        (value) => {
          if (value) return true

          return 'Name is required.'
        }
      ],
      nameRules: [
        (value) => {
          if (value) return true

          return 'Name is required.'
        },
        (value) => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        }
      ],
      typeRules: [
        (value) => {
          if (value) return true

          return 'Name is required.'
        }
      ],
      emailRules: [
        (value) => {
          if (value) return true

          return 'E-mail is required.'
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        }
      ]
    }
  },
  methods: {
    async createUser() {
      this.loading = true
      try {
        await UsersService.editUser(this.$route.params.id, this.form).then((response) => {
          this.users = response['users']
          this.$router.push({ name: 'Users' })
        })
        useSnackbarStore().openSnackbar({
          text: this.$t('FORGOT_PASSWORD_PAGE.SUCCESS'),
          color: 'success'
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
