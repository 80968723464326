<template>
  <v-table class="w-100 custom-table ml-0">
    <thead class="text-left">
    <tr>
      <th>Namespace</th>
      <th>User</th>
      <th>Password</th>
      <th width="50">Action</th>
    </tr>

    </thead>
    <tbody>
    <tr v-for="project in deployments">
      <td class="text-left">
          {{ project['name'] }}
      </td>
      <td class="text-left">
        {{ project['user'] ?? '-' }}
      </td>
      <td style="max-width:200px;" class="text-left">
        {{ project['password'] ?? '-' }}
      </td>

      <td>
        <v-menu>
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
              <v-icon>mdi-dots-vertical</v-icon>
            </div>
          </template>
          <v-list class="custom-vlist">
            <v-list-item class="pl-0 pr-0">
              <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions
              </v-list-item-title>
              <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover"
                                 @click="edit(project)">
                <div class="d-block flex-grow-1 pr-4">Modifier</div>
                <v-icon class="icon">mdi-chevron-right</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
import moment from 'moment-timezone'
import 'moment/locale/fr'
import ProjectsService from '@/services/ProjectsService.js'
moment.locale('fr')

export default {
  name: 'AccessTable',
  components: {},
  props: {
    // La prop `formData` doit être un objet avec des validations pour une meilleure gestion.
    project: {
      required: true,
      default: () => ({}) // Définissez une valeur par défaut vide si nécessaire
    }
  },
  data() {
    return {
      deployments: []
    }
  },
  beforeUnmount() {},
  methods: {
    edit(project) {
      // TODO : fix it
      alert('coming soon');
    }

  },
  mounted() {
    ProjectsService.getAccess(this.$props.project).then((response) => {
      this.deployments = response['namespaces']
    })
  }
}
</script>