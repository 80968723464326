<template>
  <v-container>
    <PageHeader subtitle="Ajouter un projet">
      <div class="d-flex justify-end">
        <div v-if="step === 4" class="mr-3">
          <v-btn @click="createProject()" :disabled="!form" class="bg-primary btn">Sauvegarder</v-btn>
        </div>
        <div v-if="step >= 1 && step < 4" class="mr-3">
          <v-btn @click="nextStep()" :disabled="checkStep()"  class="bg-primary btn">Suivant</v-btn>
        </div>
        <div v-if="step === 4" class="text-white">
          <v-btn @click="addDeployment()" class="bg-success btn">Ajouter un deploiment</v-btn>
        </div>
      </div>
    </PageHeader>

    <v-navigation-drawer
      v-if="drawer"
      style="position: fixed !important; left: auto; right: 0; min-width: 40% !important"
      class="custom-drawer"
      temporary
    >
      <Deployment :formData="formData" :drawer="drawer" @update-drawer="handleDrawerUpdate" @push-deployment="handleDeploymentUpdate" />
    </v-navigation-drawer>

    <v-stepper
      class="custom-stepper"
      v-model="step"
      :items="['Information générales', 'Dépôts', 'Kubernetes', 'Déploiements']"
    >
      <template v-slot:item.1>
        <v-card class="mt-3">
          <v-form v-model="form">
            <v-row>
              <v-col col="12">
                <v-row>
                  <v-col col="10">
                    <div class="form-group">
                      <div class="d-flex" style="font-size: 1.3em; font-weight: 700">
                        <div class="flex-grow-1">Client</div>
                        <div>
                          <v-btn
                            class="bg-primary btn"
                            @click="formData.customerAdd = true"
                            v-if="!formData.customerAdd"
                            >Ajouter un client</v-btn
                          >
                          <v-btn
                            class="bg-primary btn"
                            @click="formData.customerAdd = false"
                            v-if="formData.customerAdd"
                            >Annuler</v-btn
                          >
                        </div>
                      </div>
                      <div class="form-input" v-if="!formData.customerAdd">
                        <v-select
                          class="w-100 form-select"
                          v-model="formData.customer"
                          :items="customersList"
                        ></v-select>
                      </div>
                      <div class="" v-if="formData.customerAdd">
                        <v-text-field v-model="formData.newCustomer"></v-text-field>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-card class="mt-3">
          <v-form v-model="form">
            <v-row>
              <v-col col="12">
                <v-row>
                  <v-col col="10">
                    <div class="form-group">
                      <div class="form-label">Nom du projet</div>
                      <div class="form-input">
                        <v-text-field v-model="formData.name" :rules="rules['name']"></v-text-field>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="2">
                    <div class="form-group">
                      <div class="form-label">Estimation en J/H</div>
                      <div class="form-input">
                        <v-text-field v-model="formData.estimated"></v-text-field>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>

        <v-card class="mt-5">
          <div class="d-flex" style="font-size: 1.3em; font-weight: 700">
            <div class="flex-grow-1">Youtrack</div>
            <div>
              <v-btn
                class="bg-primary btn"
                @click="formData.youtrackAdd = true"
                v-if="!formData.youtrackAdd"
                >Ajouter un projet</v-btn
              >
              <v-btn
                class="bg-primary btn"
                @click="formData.youtrackAdd = false"
                v-if="formData.youtrackAdd"
                >Annuler</v-btn
              >
            </div>
          </div>
          <div class="form-group mt-3" v-if="!formData.youtrackAdd">
            <div class="form-label">Choisir un projet existant</div>
            <div class="form-input">
              <v-select
                class="w-100 form-select"
                v-model="formData.youtrackName"
                :items="youtrackProject"
                item-value="id"
                item-title="name"
              ></v-select>
            </div>
          </div>
          <div v-if="formData.youtrackAdd">
            <div class="form-group mt-3">
              <div class="form-label">Nom du projet</div>
              <div class="form-input">
                <v-text-field v-model="formData.youtrackObj.name"></v-text-field>
              </div>
            </div>
            <div class="form-input">
              <div class="form-label">Type</div>
              <v-select
                class="w-100 form-select"
                v-model="formData.youtrackObj.type"
                :items="['leviatan', 'services']"
                item-value="id"
                item-title="name"
              ></v-select>
            </div>
          </div>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card class="mt-3">
          <div class="form-group">
            <div class="form-label">Projet(s) Gitlabs</div>
            <div class="form-input">
              <v-autocomplete
                class="w-100 form-select"
                v-model="formData.gitlabRepos"
                item-text="title"
                :return-object="true"
                :item-props="true"
                :items="gitlabRepos"
                multiple
              >
              </v-autocomplete>
            </div>
            <div>
              <div class="d-flex mb-2 border-b pb-2" v-for="repo in formData.gitlabRepos">
                <div class="mr-2 mt-2">
                  <img src="@/assets/gitlab.png" width="30" height="30" alt="gitlab" />
                </div>
                <div class="flex-grow-1">
                  <div>
                    {{ repo['title'] }} <i class="font-weight-bold">({{ repo['id'] }})</i>
                  </div>
                  <div style="font-size: 0.8em; color: #ccc">{{ repo['namespace']['name'] }}</div>
                </div>
                <div
                  :class="{
                    'text-success': repo['webhooksExist'],
                    'text-danger': !repo['webhooksExist']
                  }"
                >
                  {{ repo['webhooksExist'] ? 'Configuré' : 'Non configuré' }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card class="mt-3" flat>
          <div class="form-group">
            <div class="form-label">Cluster kubernetes</div>
            <div class="form-input">
              <v-select
                class="w-100 form-select"
                v-model="clustersValue"
                :rules="rules['cluster']"
                :items="this.clusters"
                item-value="id"
                item-title="name"
              ></v-select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label d-flex">
              <div class="flex-grow-1">Namespace(s) kubernete</div>
            </div>
            <div class="form-input" v-if="!formData.addNamespace">
              <v-select
                class="w-100 form-select"
                v-model="formData.namespaces"
                :items="this.namespaces"
                multiple
              ></v-select>
            </div>

            <div class="form-label d-flex">
              <div class="flex-grow-1">Environnement</div>
            </div>
            <div class="form-control">
              <v-checkbox label="Dev" v-model="formData.dev"></v-checkbox>
              <div class="form-label d-flex">
                <div class="flex-grow-1">Nom du namespace</div>
              </div>
              <v-text-field v-model="formData.namespaceDev"></v-text-field>
            </div>
            <div class="form-control">
              <v-checkbox label="Recette" v-model="formData.rec"></v-checkbox>
              <div class="form-label d-flex">
                <div class="flex-grow-1">Nom du namespace</div>
              </div>
              <v-text-field v-model="formData.namespaceRec"></v-text-field>
            </div>
          </div>
        </v-card>
        <v-card class="mt-3">
          <div class="form-label d-flex">
            <div class="flex-grow-1">Modules préinstallés</div>
          </div>
          <div class="d-flex">
            <v-checkbox label="Mailhog" v-model="formData.mailhog"></v-checkbox>
            <v-checkbox label="Mysql" v-model="formData.mysql"></v-checkbox>
            <v-checkbox label="Postgresql" v-model="formData.postgresql"></v-checkbox>
          </div>
        </v-card>
      </template>

      <template v-slot:item.4>
        <v-card>
          Déploiements
          <v-row>
            <v-col cols="6" v-for="deploy in formData.deployments">
              <DeploymentDetail :deployment="deploy" />
            </v-col>
          </v-row>
        </v-card>
      </template>

      <template v-slot:actions>
        <v-card class="mt-3">
          <v-row class="pl-4 pr-4">
            <v-col cols="6">
              <v-btn :disabled="step === 1" color="primary" @click="prevStep" class="mr-3">
                Précédent
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :disabled="step === 4" color="primary" @click="nextStep"> Suivante </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-stepper>
  </v-container>
</template>

<script>
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import UsersService from '@/services/UsersService.js'
import ProjectsService from '@/services/ProjectsService.js'
import PageHeader from '@/components/ui/bloc/PageHeader.vue'
import Deployment from '@/views/Projects/deployment/Deployment.vue'
import DeploymentDetail from '@/views/Projects/deployment/DeploymentDetail.vue'
moment.locale('fr')

export default {
  name: 'ProjectsAdd',
  components: { Deployment, PageHeader, DeploymentDetail },
  data() {
    return {
      form: null,
      clustersValue: null,
      dialog: false,
      drawer: false,
      dialogData: [],
      nameValue: null,
      namespaces: [],
      clusters: [],
      customersList: [],
      gitlabRepos: [],
      youtrackProject: [],
      step: 1,
      youtrackAdd: false,
      formDeploymentData: {
        name: null,
        namespace: null,
        git: null,
        ingress: null,
        image: null,
        command: null,
        url: null,
        htaccess: false,
        envs: [],
        port: 80
      },
      formData: {
        name: null,
        customer: null,
        customerAdd: false,
        newCustomer: null,
        youtrackName: null,
        youtrackAdd: false,
        youtrackObj: { name: null, type: 'service' },
        gitlabRepos: [],
        estimated: null,
        clusters: null,
        deployments: [],
        namespaceDev: null,
        namespaceRec: null,
        mysql: false,
        mailhog: true,
        postgresql: false,
        dev: true,
        rec: true,
        addNamespace: true
      },
      rules: {
        name: [
          (value) => {
            if (value) return true
            return 'Le nom du projet est requis.'
          }
        ],
        cluster: [
          (value) => {
            if (value) return true
            return 'Le cluster est requis.'
          }
        ]
      }
    }
  },
  beforeUnmount() {},
  watch: {
    clustersValue: {
      handler(val) {
        this.formData.clusters = val
      },
      deep: true
    },
    'formDeploymentData.git': {
      handler(newValue) {
        if (newValue == null) return

        ProjectsService.gitGetDockerfileCommand(newValue.id).then((response) => {
          this.formDeploymentData.command = response.command
        })

        ProjectsService.gitGetCiImage(newValue.id).then((response) => {
          this.formDeploymentData.image = response.ci
        })
      },
      immediate: true
    }
  },
  methods: {
    addDeployment() {
      this.formDeploymentData = {
        name: null,
        namespace: null,
        git: null,
        ingress: null,
        image: null,
        command: null,
        url: null,
        htaccess: false
      }
      this.drawer = true
    },
    addDeploymentForm() {
      this.drawer = false
      this.formData.deployments.push(this.formDeploymentData)
    },
    checkStep() {
      if(this.step === 1) {
        if(this.formData.customer === null && this.formData.customerAdd === false) {
          return true;
        } else if(this.formData.newCustomer === null && this.formData.customerAdd === true) {
          return true;
        }

        if(this.formData.name === null || this.formData.name === '') {
          return true;
        }

        if(this.formData.estimated === null || this.formData.estimated === '') {
          return true;
        }
        if(this.formData.youtrackName === null && this.formData.youtrackAdd === false) {
          return true;
        } else if((this.formData.youtrackObj['name'] === null || this.formData.youtrackObj['name'] === '') && this.formData.youtrackAdd === true) {
          return true;
        }
      }

      return false;
    },
    nextStep() {
      console.log(this.formData);
      if (this.step < 4) {
        this.step++
      }

      if(this.step === 4) {
        console.log('passe step 4');
        if(this.formData.dev) {
          if(this.formData.postgresql) {
            console.log('postgresql dev');
          }
          if(this.formData.mysql) {
            this.formData.deployments.push({
              name: 'mysql',
              namespace: this.formData.namespaceDev,
              git: null,
              port: 3306,
              ingress: null,
              image: 'mysql:5.8',
              command: null,
              url: null,
              htaccess: false,
              envs: []
            });
          }
          if(this.formData.mailhog) {
            this.formData.deployments.push({
              name: 'mailhog',
              namespace: this.formData.namespaceDev,
              git: null,
              port: 1025,
              ingress: 'dev.mailhog.' + this.formData.name,
              image: 'mailhog/mailhog:latest',
              command: null,
              url: null,
              htaccess: true,
              envs: []
            });
          }
        }

        if(this.formData.rec) {
          if(this.formData.postgresql) {
            console.log('postgresql rec');
          }
          if(this.formData.mysql) {
            this.formData.deployments.push({
              name: 'mysql',
              namespace: this.formData.namespaceRec,
              git: null,
              port: 3306,
              ingress: null,
              image: 'mysql:5.8',
              command: null,
              url: null,
              htaccess: false,
              envs: []
            });
          }
          if(this.formData.mailhog) {
            this.formData.deployments.push({
              name: 'mailhog',
              namespace: this.formData.namespaceRec,
              git: null,
              port: 1025,
              ingress: 'rec.mailhog.' + this.formData.name,
              image: 'mailhog/mailhog:latest',
              command: null,
              url: null,
              htaccess: true,
              envs: []
            });
          }
        }
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--
      }
    },
    handleDrawerUpdate(value) {
      this.drawer = value; // Met à jour l'état du drawer depuis l'événement enfant
    },
    handleDeploymentUpdate(value) {
      this.formData.deployments.push(value); // Met à jour l'état du drawer depuis l'événement enfant
    },
    async createProject() {
      this.loading = true
      try {
        this.formData.namespaces = this.formData.name

        await ProjectsService.projectAdd(this.formData).then((response) => {
          this.dialog = true
          this.dialogData = response.services
          //useSnackbarStore().openSnackbar({
          //  text: 'Ajout du projet éffectuée',
          //  color: 'success'
          //})
          //this.$router.push({ name: 'Projects' });
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    if (localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' })
    }

    UsersService.namespaceList(this.formData.clusters).then((response) => {
      this.namespaces = response['namespaces']
    })

    UsersService.clustersList().then((response) => {
      this.clusters = response
    })

    ProjectsService.gitlabRepoList().then((response) => {
      response['repos'].forEach((item) => {
        this.gitlabRepos.push(item)
      })
    })

    ProjectsService.projectsYoutrack().then((response) => {
      response['projects'].forEach((item) => {
        this.youtrackProject.push(item)
      })
    })

    ProjectsService.getCustomersList().then((response) => {
      response['customers'].forEach((item) => {
        this.customersList.push(item)
      })
    })
  }
}
</script>