<template>
  <v-row class="ml-2">
    <v-col cols="12">
    <v-card>
      <div>
        <div style="font-size:1.3em;font-weight:bold;">{{ $props.deployment.name }}</div>
        <div style="color:#9a9a9a;">{{ $props.deployment.namespace }}</div>

        <div class="form-group mt-4">
          <div class="form-label">Ingress</div>
          <div>
            <div v-if="$props.deployment.ingress !== null">https://{{ $props.deployment.ingress }}.dev-leviatan.fr</div>
            <div v-if="$props.deployment.ingress === null">-</div>
            <div class="d-flex">
              <div class="text-success" v-if="$props.deployment.htaccess"><v-icon>mdi-check</v-icon></div>
              <div class="text-danger" v-if="!$props.deployment.htaccess"><v-icon>mdi-close</v-icon></div>
              <div>Htaccess</div>
            </div>
            <div><v-icon>mdi-server-network-outline</v-icon>  {{ $props.deployment.port }}</div>
          </div>
        </div>

        <div class="mt-4 form-group">
          <div class="form-label">Image</div>
          <div>{{ $props.deployment.image }}</div>
        </div>


        <div class="form-group mt-4">
          <div class="form-label">Commande</div>
          <div class="">{{ $props.deployment.command ?? '-' }}</div>
        </div>

        <div v-if="false">{{$props.deployment.envs ?? '-'}}</div>
      </div>
    </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'
import 'moment/locale/fr'
import ProjectsService from '@/services/ProjectsService.js'
moment.locale('fr')

export default {
  name: 'DeploymentDetail',
  components: {},
  props: {
    // La prop `formData` doit être un objet avec des validations pour une meilleure gestion.
    deployment: {
      required: true,
      default: () => ({}) // Définissez une valeur par défaut vide si nécessaire
    }
  },
  data() {
    return {
      localDrawer: { ...this.drawer },
      localFormData: {... this.formData},
      form: null,
      formDeploymentData: {
        name: null,
        namespace: null,
        git: null,
        ingress: null,
        image: null,
        command: null,
        url: null,
        htaccess: false,
        envs: [],
        port: 80
      },
      rules: {
        name: [
          (value) => {
            if (value) return true
            return 'Le nom du projet est requis.'
          }
        ],
        port: [
          (value) => {
            if (value >= 1) return true
            return 'Le port doit être supérieur à 1'
          },
          (value) => {
            if (value <= 65535) return true
            return 'Le port doit être supérieur à 65535'
          }
        ],
        cluster: [
          (value) => {
            if (value) return true
            return 'Le cluster est requis.'
          }
        ]
      }
    }
  },
  beforeUnmount() {},
  watch: {
    'formDeploymentData.git': {
      handler(newValue) {
        if (newValue == null) return

        ProjectsService.gitGetDockerfileCommand(newValue.id).then((response) => {
          this.formDeploymentData.command = response.command
        })

        ProjectsService.gitGetCiImage(newValue.id).then((response) => {
          this.formDeploymentData.image = response.ci
        })
      },
      immediate: true
    }
  },
  methods: {
    addDeploymentForm() {
      this.$emit("update-drawer", false);
      this.$emit("push-deployment", this.formDeploymentData);
    },
    updateValue() {
      this.$emit("update-drawer", false); // Mise à jour via l'événement de binding automatique
    }
  },
  beforeMount() {
    console.log(this.$props);
  }
}
</script>