<template>
  <div v-html="convertedText" class="ansi-log"></div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import AnsiToHtml from 'ansi-to-html';
import { defineProps } from 'vue';

// Définis les props attendues
const props = defineProps({
  logText: String
});

// Instance de conversion
const convert = new AnsiToHtml({
  fg: '#FFF', // Couleur de texte par défaut
  bg: '#000', // Couleur de fond par défaut
  newline: true, // Convertir les retours à la ligne en balises <br>
  escapeXML: true, // Échapper les entités HTML dans l'entrée
  stream: true,
  colors: ['#ffffff', '#df5353', '#ffffff', 'orange', 'cyan', '#43c537', '#98c766']
});

// Texte converti en HTML
const convertedText = ref('');

// Regarder pour les changements dans logText et mettre à jour convertedText
watchEffect(() => {
  convertedText.value = convert.toHtml(props.logText);
});
</script>

<style>
.ansi-log {
  white-space: pre-wrap; /* Préserve les espacements et retours à la ligne */
}
</style>
