<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader/>
      </v-col>
    </v-row>

    <PageHeader v-if="!loading" subtitle="Liste des projets">

    </PageHeader>

    <div v-if="version.length > 0" style="background:white;position:fixed;width:20%;right:0;height:100vh;top:0;z-index:999;
    box-shadow: 1px 0 20px #00000014;overflow: scroll;">
      <div style="position:absolute;right:15px;top:15px;font-size:1.5em;" @click="version = []"><v-icon>mdi-close</v-icon></div>
      <div v-for="repo in version" class="d-flex flex-column" style="padding:10px;border-bottom:1px solid #eee;">
        <h2>{{repo['name']}}</h2>
        <div style="font-size:0.9em;color:#aaa;margin-bottom:15px;">{{ repo['rec']['before']['ref'] }} -> {{ repo['rec']['last']['ref'] }}</div>

        <div v-for="mr in repo['rec']['mrs']" style="border-bottom: 1px solid #eee;">
          <div>{{ mr['title'] }}</div>
          <div style="font-size:0.9em;color:#aaa;margin-bottom:5px;" v-if="mr['ticket'] !== null">{{ mr['ticket'] }}</div>
          <div class="text-danger" style="margin-bottom:5px;font-size:0.9em;" v-if="mr['ticket'] === null">Pas de ticket</div>
        </div>
      </div>
    </div>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="p-0">
          <div class="d-flex">
            <v-table class="w-100 custom-table ml-0">
              <thead class="text-left">
              <tr>
                <th>Nom</th>
                <th>Namespaces</th>
                <th>Déploiements</th>
                <th>Tag Recette</th>
                <th>Tag Prod</th>
                <th width="50">Action</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="project in requests">

                <td class="text-left d-flex">
                  <div class="pt-2">
                    <div>{{ project['name'] }}</div>
                    <div style="font-size:0.8em;color:#aaa;"><span v-for="cluster in project['cluster']">{{ cluster['name']}}</span></div>
                    <div style="font-size:0.8em;" class="text-danger" v-if="project['cluster'].length === 0">Aucun</div>
                  </div>
                </td>
                <td class="text-left">
                  <div class="d-flex" style="font-size:0.9em;">
                    <div  class="chips-primary mr-2" v-for="(namespace) in project['namespaces']['values']">
                      {{ namespace['name']}}
                    </div>
                  </div>
                </td>
                <td style="max-width:200px;" class="text-left">
                  <div>{{ project['deployments'] }} déploiement{{ project['deployments'] ? 's' : '' }}</div>
                </td>
                <td style="max-width:200px;" class="text-left">
                  <div @click="showVersion(project['id'])">{{ project['tags']['rec'] }}</div>
                </td>
                <td style="max-width:200px;" class="text-left">
                  <div>{{ project['tags']['prod'] }}</div>
                </td>

                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="custom-vlist">
                      <v-list-item class="pl-0 pr-0">
                        <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions
                        </v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover"
                                           @click="deleteRequest(project)">
                          <div class="d-block flex-grow-1 pr-4">Supprimer</div>
                          <v-icon class="icon">mdi-chevron-right</v-icon>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import { fr } from 'date-fns/locale';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import JobService from '@/services/JobService.js';
import Loader from '@/components/ui/Loader.vue';
import { format } from 'date-fns';
import { useAuthStore } from '@/stores/AuthStore.js';
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import AuthService from '@/services/AuthService.js';
import ProjectsUserService from '@/services/ProjectsUserService.js'

moment.locale('fr');

export default {
  name: 'ProjectUserListPage',
  computed: {
    fr() {
      return fr
    },
    user() {
      return this.authStore.user;
    }
  },
  components: { PageHeader, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      requests: [],
      date: new Date(),
      moment: moment,
      version : [],
      hour: parseInt(format(new Date(), 'HH', { locale: fr }))
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadHomeOffice() {
      this.loading = true
      try {
        ProjectsUserService.projectsList().then(response => {
          this.requests = response['projects'];
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    showVersion(id) {
      ProjectsUserService.projectsVersion(id).then(response => {
        console.log(response);
        this.version = response['repoList'];
      });
      console.log('show version');
    }
  },
  mounted() {
    if(!AuthService.hasAccess('dev_homeoffice')) {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true;
    this.loadHomeOffice();
  }

}
</script>
