<template>
  <v-row class="ml-2">
    <div @click="this.updateValue()" style="font-size:1.3em;position:fixed;right:45px;top:20px;">
      <v-icon>mdi-close</v-icon>
    </div>
    <v-col cols="12" class="v-card-title mt-5">
      Informations déploiement
    </v-col>
    <v-col cols="12">
      <v-form v-model="form">
        <div class="form-group">
          <div class="flex-grow-1 form-label">Namespace</div>
          <v-select
            :items="[localFormData.namespaceDev, localFormData.namespaceRec]"
            v-model="formDeploymentData.namespace"
            class="w-100 form-select"
          ></v-select>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">Dépot git</div>
          <v-select
            class="w-100 form-select"
            v-model="formDeploymentData.git"
            item-text="title"
            :return-object="true"
            :item-props="true"
            :items="localFormData.gitlabRepos"
          ></v-select>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">Nom du déploiement</div>
          <v-text-field v-model="formDeploymentData.name" class="form-input"
                        :rules="rules['name']"></v-text-field>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">image</div>
          <v-text-field v-model="formDeploymentData.image"
                        :rules="rules['name']"></v-text-field>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">Commande</div>
          <v-text-field v-model="formDeploymentData.command"
                        :rules="rules['name']"></v-text-field>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">Port réseau</div>
          <v-text-field v-model="formDeploymentData.port" type="number"
                        :rules="rules['port']"></v-text-field>
        </div>
        <div class="form-group">
          <div class="flex-grow-1 form-label">Domaine</div>
          <v-text-field class="input-field" v-model="formDeploymentData.ingress" >
            <template v-slot:append-inner>
              <span>.dev-leviatan.fr</span>
            </template>
          </v-text-field>
          <v-checkbox label="Htaccess" v-model="formDeploymentData.htaccess"></v-checkbox>
        </div>
      </v-form>

      <div>
        <v-btn color="primary" :disabled="!form" @click="addDeploymentForm()">Ajouter</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'
import 'moment/locale/fr'
import ProjectsService from '@/services/ProjectsService.js'
moment.locale('fr')

export default {
  name: 'Deployment',
  components: {},
  props: {
    // La prop `formData` doit être un objet avec des validations pour une meilleure gestion.
    formData: {
      type: {
        name: null,
        customer: null,
        customerAdd: false,
        newCustomer: null,
        youtrackName: null,
        youtrackAdd: false,
        youtrackObj: { name: null, type: 'service' },
        gitlabRepos: [],
        estimated: null,
        clusters: null,
        deployments: [],
        namespaceDev: null,
        namespaceRec: null,
        mysql: false,
        mailhog: true,
        postgresql: false,
        dev: true,
        rec: true,
        addNamespace: true
      },
      required: true,
      default: () => ({}) // Définissez une valeur par défaut vide si nécessaire
    },
    drawer: {
      required: true,
      default: false // Définissez une valeur par défaut vide si nécessaire
    }
  },
  data() {
    return {
      localDrawer: { ...this.drawer },
      localFormData: {... this.formData},
      form: null,
      formDeploymentData: {
        name: null,
        namespace: null,
        git: null,
        ingress: null,
        image: null,
        command: null,
        url: null,
        htaccess: false,
        envs: [],
        port: 80
      },
      rules: {
        name: [
          (value) => {
            if (value) return true
            return 'Le nom du projet est requis.'
          }
        ],
        port: [
          (value) => {
            if (value >= 1) return true
            return 'Le port doit être supérieur à 1'
          },
          (value) => {
            if (value <= 65535) return true
            return 'Le port doit être supérieur à 65535'
          }
        ],
        cluster: [
          (value) => {
            if (value) return true
            return 'Le cluster est requis.'
          }
        ]
      }
    }
  },
  beforeUnmount() {},
  watch: {
    'formDeploymentData.git': {
      handler(newValue) {
        if (newValue == null) return

        ProjectsService.gitGetDockerfileCommand(newValue.id).then((response) => {
          this.formDeploymentData.command = response.command
        })

        ProjectsService.gitGetCiImage(newValue.id).then((response) => {
          this.formDeploymentData.image = response.ci
        })
      },
      immediate: true
    }
  },
  methods: {
    addDeploymentForm() {
      this.$emit("update-drawer", false);
      this.$emit("push-deployment", this.formDeploymentData);
    },
    updateValue() {
      this.$emit("update-drawer", false); // Mise à jour via l'événement de binding automatique
    }
  },
  beforeMount() {
    console.log(this.$props);
  }
}
</script>